export const searchByCode = [
  {
    identifier: 'part_code',
    rules: [
      { required: true, message: 'برجاء ملء هذه الخانة' },
      { min: 5, message: 'يجب عليك إدخال 5 أحرف على الأقل في هذه الخانة' },
    ],
    placeholder: 'code-placeholder',
    label: 'code-label',
    type: 'input',
  },
];

export const searchByType = [
  {
    identifier: 'maker_id',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    placeholder: 'maker-placeholder',
    label: 'maker-label',
    type: 'select',
    dynamic: true,
    optionsEntitiy: {
      sliceidentifier: 'maker',
      accessor: 'id',
      label: 'name',
    },
  },
  {
    identifier: 'model_id',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    placeholder: 'model-placeholder',
    label: 'model-label',
    type: 'select',
    dynamic: true,
    optionsEntitiy: {
      sliceidentifier: 'model',
      accessor: 'id',
      label: 'name',
    },
    filterParams: ['maker_id'],
  },
  {
    identifier: 'brand_id',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    placeholder: 'brand-placeholder',
    label: 'brand-label',
    type: 'select',
    dynamic: true,
    optionsEntitiy: {
      sliceidentifier: 'brand',
      accessor: 'id',
      label: 'name',
    },
  },
];
