export const formFields = [
  {
    identifier: 'percentage_added',
    label: 'percentage-added',
    type: 'number',
  },
  {
    identifier: 'models',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    label: 'models',
    placeholder: 'model-sublabel',
    type: 'checkboxGroup',
    dynamic: true,
    optionsEntitiy: {
      sliceidentifier: 'model',
      accessor: 'id',
      label: 'name',
      params: { show_all: true, reset: true },
    },
  },
  {
    identifier: 'brand',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    label: 'brands',
    placeholder: 'select-placeholder',
    type: 'select',
    dynamic: true,
    optionsEntitiy: {
      sliceidentifier: 'brand',
      accessor: 'id',
      label: 'name',
      params: { show_all: true },
    },
  },
  {
    identifier: 'format',
    rules: [{ required: true, message: 'برجاء ملء هذه الخانة' }],
    label: 'extract',
    placeholder: 'select-placeholder',
    type: 'select',
    staticOptions: [
      {
        label: 'pdf',
        value: 'pdf',
      },
      {
        label: 'xls',
        value: 'xls',
      },
    ],
  },
];
