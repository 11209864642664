import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import parts from 'redux/parts';
import maker from 'redux/maker';
import model from 'redux/model';
import brand from 'redux/brand';
import category from 'redux/category';
import user from 'redux/user';

import { history } from 'router';

import Table from 'components/molecules/Table';
import Field from 'components/molecules/Field';
import PurchaseButton from 'components/molecules/PurchaseButton';
import CartModal from 'components/molecules/CartModal';
import useCart from 'hooks/useCart';

import { dropDown } from './tableColumns';
import newIcon from 'assets/icons/new.png';

import './index.scss';

const ListPrices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useRef(queryString.parse(location.search));
  const { t } = useTranslation('common');

  const filteredParts = useSelector(parts.selectors.selectAll);
  const loading = useSelector(parts.selectors.getLoadingState);
  const { total_entries: total, current_page } = useSelector(parts.selectors.getMetaData);

  const currentUser = useSelector(user.selectors.getCurrentUser);
  const currentMaker = useSelector((state) => maker.selectors.selectById(state, params.current.maker_id));
  const currentModel = useSelector((state) => model.selectors.selectById(state, params.current.model_id));
  const currentBrand = useSelector((state) => brand.selectors.selectById(state, params.current.brand_id));
  const currentCatergory = useSelector((state) => category.selectors.selectById(state, params.current.category_id));

  const {
    onSubmit,
    updateRecords,
    onModalClose,
    resetCartItems,
    cartItems,
    cartLoader,
    cartAlert,
    cartTotal,
  } = useCart();

  useEffect(() => {
    if (location.search !== '') {
      params.current = queryString.parse(location.search);
      dispatch(parts.thunks.filterParts({ params: params.current }));
      dispatch(maker.thunks.fetch());
      resetCartItems();
    }
  }, [dispatch, location.search, resetCartItems]);

  const onChange = useCallback((identifier, value) => {
    if (value) {
      const newParams = queryString.stringify({ ...params.current, [identifier]: value });
      history.push(`/parts?${newParams}`);
    }
  }, []);

  const onPageChange = useCallback(
    ({ current }) => {
      dispatch(parts.thunks.filterParts({ params: { ...params.current, page: current }, selectedItems: cartItems }));
      window.scrollTo(0, 0);
    },
    [dispatch, cartItems],
  );

  const columns = [
    {
      title: 'index',
      dataIndex: 'id',
      render: (_, __, index) => (current_page - 1) * 10 + index + 1,
      width: '2%',
    },
    {
      title: 'product-name',
      dataIndex: 'arabic_name',
      render: (text, record) => (
        <React.Fragment>
          {text} {record.newly_updated && <img src={newIcon} alt="New" />}
        </React.Fragment>
      ),
      width: '20%',
    },
    {
      title: 'model',
      dataIndex: 'show_model_names',
      render: (value) => (params.current.type !== 'code' ? currentModel?.name || value : null),
    },
    {
      title: 'brand',
      dataIndex: 'brand_image',
      render: (value, record) => <img src={value} alt={record.brand_name} className="custom-table-img" />,
    },
    {
      title: 'country',
      dataIndex: 'country_name',
    },
    {
      title: 'remark',
      dataIndex: 'remark',
      width: '5%',
    },
    {
      title: 'price',
      dataIndex: 'price_and_promotion',
      render: (value, record) => {
        return (
          <>
            {value.promotion && currentUser.show_promotions && record['on_promotion?'] ? (
              <>
                <s>{Math.floor(value.price)?.toLocaleString()}</s>
                <br></br>
                {Math.floor(value.promotion)?.toLocaleString()}
              </>
            ) : (
              Math.floor(record.price)?.toLocaleString()
            )}
          </>
        );
      },
    },
    {
      title: 'quantity',
      dataIndex: ['in_cart', 'quantity'],
      isEditable: true,
      type: 'number',
    },
    {
      title: 'total',
      dataIndex: 'total',
      render: (_, record) => {
        return (
          (
            Math.floor(
              currentUser.show_promotions && record['on_promotion?']
                ? record.price_and_promotion?.promotion
                : record.price,
            ) * record.in_cart.quantity
          )?.toLocaleString() || 0
        );
      },
    },
    {
      title: 'images',
      dataIndex: 'images',
      type: 'images',
      width: '20%',
    },
    {
      title: 'code',
      dataIndex: 'code',
    },
  ];

  return (
    <div
      title={`${t('list-prices')} ${currentModel?.name ? `/ ${currentModel.name}` : ''} ${
        currentMaker?.name ? `/ ${currentMaker.name}` : ''
      } ${currentBrand?.name ? `/ ${currentBrand.name}` : ''} ${
        currentCatergory?.name ? `/ ${currentCatergory.name}` : ''
      }`}
    >
      {params.current.type !== 'code' && (
        <div className="custom-drop-down">
          <Field
            onChange={onChange}
            filteredState={{ maker_id: params.current.maker_id }}
            value={parseInt(params.current.model_id) || undefined}
            {...dropDown}
            localePath="table-fields"
            name={undefined}
          />
        </div>
      )}
      <CartModal alert={cartAlert} onCancel={onModalClose} total={cartTotal} />
      <PurchaseButton buttonTitle={t('add-to-cart')} sideLabel={t('add-to-cart-side-label')} onClick={onSubmit} />
      <Table
        columns={columns}
        dataSource={filteredParts}
        updateDataSource={updateRecords}
        loading={loading || cartLoader}
        rowKey="id"
        localePath="table-fields"
        pagination={{ total, pageSize: 1000 }}
        onChange={onPageChange}
      />
      <PurchaseButton buttonTitle={t('add-to-cart')} sideLabel={t('add-to-cart-side-label')} onClick={onSubmit} />
    </div>
  );
};

export default ListPrices;
