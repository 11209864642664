export const fields = [
  {
    identifier: 'email',
    rules: [{ required: true, message: 'Please input your E-mail!' }, { type: 'email' }],
    placeholder: 'E-mail',
    type: 'input',
  },
  {
    identifier: 'password',
    rules: [{ required: true, message: 'Please input your Password!' }],
    placeholder: 'Password',
    type: 'password',
  },
  {
    identifier: 'remember',
    valuePropName: 'checked',
    type: 'checkbox',
    customValue: 'Remember me',
  },
];
