import { useCallback, useReducer } from 'react';
import { Form, Button, Alert } from 'antd';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import Field from 'components/molecules/Field';
import customFormReducer from './reducer';

import './index.scss';

const CustomForm = ({
  onSubmit,
  submitLabel,
  initialValues = {},
  validateStatus,
  error,
  fields,
  inputStyle,
  itemLayout,
  localePath = '',
}) => {
  const [state, dispatch] = useReducer(customFormReducer, initialValues);
  const [form] = Form.useForm();
  const onChange = useCallback(
    (identifier, value) => {
      dispatch({ type: 'change', [identifier]: value });
      form.setFieldsValue({ [identifier]: value });
    },
    [dispatch, form],
  );

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      className="custom-form"
      initialValues={initialValues}
      {...itemLayout}
    >
      {validateStatus === 'error' && <Alert message={error} type="error" />}
      {fields.map(({ identifier, filterParams, ...fieldProps }) => (
        <Field
          key={identifier}
          identifier={identifier}
          inputStyle={inputStyle}
          filteredState={filterParams && pick(state, filterParams)}
          value={state[identifier]}
          onChange={onChange}
          localePath={localePath}
          {...fieldProps}
        />
      ))}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {submitLabel}
        </Button>
      </Form.Item>
    </Form>
  );
};

CustomForm.PropType = {
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.object,
  validateStatus: PropTypes.string,
  error: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  inputStyle: PropTypes.string,
  itemLayout: PropTypes.object,
  localePath: PropTypes.string,
};

export default CustomForm;
