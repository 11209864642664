import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import maker from 'redux/maker';
import Section from 'components/molecules/Section';
import ImagesList from 'components/molecules/ImagesList';

const Makers = () => {
  const dispatch = useDispatch();
  const makers = useSelector(maker.selectors.selectAll);
  const loading = useSelector(maker.selectors.getLoadingState);

  useEffect(() => {
    dispatch(maker.thunks.fetch());
  }, [dispatch]);

  return (
    <Section>
      <ImagesList
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={makers}
        loading={loading}
        baseUrl="/models?maker_id="
      />
    </Section>
  );
};

export default Makers;
