import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import * as actions from './actions';
import selectors from './selectors';
import adapter from './adapter';

const calculateCartTotal = (items) => items.reduce((acc, item) => (acc += Math.floor(item.price) * item.quantity), 0);

const slice = createSlice({
  name: 'Cart',
  initialState: adapter.getInitialState({
    loading: false,
    alert: null,
    total: 0,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(actions.setAlert, (state, action) => {
        state.alert = action.payload;
      })
      .addCase(thunks.addItems.pending, (state, action) => {
        const { loader } = action.meta.arg;
        if (loader) {
          state.loading = true;
          state.alert = null;
        }
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        state.loading = false;
        adapter.setAll(state, action.payload);
        state.total = calculateCartTotal(action.payload);
      })
      .addCase(thunks.addItems.fulfilled, (state, action) => {
        const { loader } = action.meta.arg;
        adapter.setAll(state, action.payload.cart_items);
        state.total = calculateCartTotal(action.payload.cart_items);
        if (loader) {
          state.loading = false;
          state.alert = { message: 'items added successfully', type: 'success', subtitle: 'go to cart page' };
        }
      })
      .addCase(thunks.removeItems.fulfilled, (state, action) => {
        const { ids, cancel, total } = action.meta.arg;
        state.loading = false;
        if (cancel) {
          state.alert = { message: 'order canceled successfully', type: 'success' };
          state.total = 0;
        } else {
          state.total -= total || 0;
        }
        adapter.removeMany(state, ids);
      })
      .addCase(thunks.submitCart.fulfilled, (state, action) => {
        state.loading = false;
        state.alert = { message: 'submitted successfully', type: 'success' };
        adapter.removeAll(state);
        state.total = 0;
      })
      .addMatcher(
        isAnyOf(thunks.fetch.pending, thunks.submitCart.pending, thunks.removeItems.pending),
        (state, action) => {
          state.loading = true;
          state.alert = null;
        },
      )
      .addMatcher(
        isAnyOf(
          thunks.fetch.rejected,
          thunks.submitCart.rejected,
          thunks.removeItems.rejected,
          thunks.addItems.rejected,
        ),
        (state, action) => {
          state.loading = false;
          if (action.payload?.errors[0] !== 'You need to sign in or sign up before continuing.')
            state.alert = { message: action.payload?.errors || 'something went wrong', type: 'error' };
          state.total = 0;
          adapter.removeAll(state);
        },
      );
  },
});

const cart = {
  slice,
  thunks,
  actions,
  selectors,
};
export default cart;
