import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import cart from 'redux/cart';
import Section from 'components/molecules/Section';
import Table from 'components/molecules/Table';
import { columns } from './tableColumns';
import CartModal from 'components/molecules/CartModal';
import useCart from 'hooks/useCart';

import ButtonSection from './components/ButtonSection';

import './index.scss';

const Cart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('cart');
  const cartItems = useSelector(cart.selectors.selectAll);
  const cartItemsIds = useSelector(cart.selectors.selectIds);
  const cartTotal = useSelector(cart.selectors.getCartTotal);
  const { onModalClose, cartLoader, cartAlert } = useCart();

  useEffect(() => {
    dispatch(cart.thunks.fetch({ show_all: true }));
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(cart.thunks.submitCart(cartItems));
  };

  const onCancel = () => {
    dispatch(cart.thunks.removeItems({ ids: cartItemsIds, cancel: true }));
  };

  const onDelete = ({ id, price, quantity }) => () => {
    dispatch(cart.thunks.removeItems({ ids: [id], total: price * quantity }));
  };

  const extraColumns = [
    ...columns,
    {
      title: 'delete',
      render: (_, record) => (
        <Popconfirm title={t('delete-confirm')} cancelText={t('no')} okText={t('yes')} onConfirm={onDelete(record)}>
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const updateTable = (record) => {
    dispatch(cart.thunks.addItems({ items: [record] }));
  };

  return (
    <div title={t('your-cart')}>
      <Typography.Text className="custom-subtitle">{`${t(
        'cart-total',
      )} ${cartTotal?.toLocaleString()}`}</Typography.Text>
      <CartModal alert={cartAlert} onCancel={onModalClose} total={cartTotal} hideExtraButtons />
      <ButtonSection
        submitTitle={t('submit-title')}
        cancelTitle={t('cancel-title')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        confirmText={t('cancel-confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        disabled={cartTotal === 0}
      />
      <Table
        columns={extraColumns}
        dataSource={cartItems}
        updateDataSource={updateTable}
        rowKey="id"
        localePath="table-fields"
        loading={cartLoader}
      />
      <ButtonSection
        submitTitle={t('submit-title')}
        cancelTitle={t('cancel-title')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        confirmText={t('cancel-confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        disabled={cartTotal === 0}
      />
    </div>
  );
};

export default Cart;
