import { Route, Switch } from 'react-router';

import Login from 'screens/Login';
import Authentication from 'screens/Authentication';

const PublicRoutes = () => (
  <Switch>
    <Route path="/clients/sign_in" component={Login} exact />
    <Route path="/clients/two_factor_authentication" component={Authentication} exact />
  </Switch>
);

export default PublicRoutes;
