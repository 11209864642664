import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

import './index.scss';

const ImageCard = ({ imageUrl, imageName, url, brand }) => {
  return (
    <Link to={url}>
      <Card className={`image-card ${brand ? 'brand-card' : ''}`} hoverable>
        <img src={imageUrl} alt="" />
        <Typography.Title level={2} strong>
          {imageName}
        </Typography.Title>
      </Card>
    </Link>
  );
};

export default ImageCard;
