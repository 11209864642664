import axios from 'utils/requestHelper';

const fetchByFilters = (params) => axios.get('v1/clients/parts', { params: { ...params, per_page: 1000 } });
const fetchPartsOnPromotions = (params) =>
  axios.get('v1/clients/parts/promotions', { params: { ...params, per_page: 1000 } });

const downloadParts = (params) =>
  axios.get(`v1/clients/parts/list_prices.${params.format}`, { params, responseType: 'blob' });

const fetchCount = () => axios.get('v1/clients/parts/count');

const PartsAPI = {
  fetchByFilters,
  downloadParts,
  fetchPartsOnPromotions,
  fetchCount,
};

export default PartsAPI;
