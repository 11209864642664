import { useEffect, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { Typography } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import parts from 'redux/parts';
import './index.scss';

const ActionCable = memo(({ onReceived }) => {
  return <ActionCableConsumer channel="PartsNumberChannel" onReceived={onReceived} />;
});

const LiveParts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const count = useSelector(parts.selectors.getCount);

  useEffect(() => {
    if (!count) dispatch(parts.thunks.fetchPartsCount());
  }, [dispatch, count]);

  const onReceived = useCallback(
    ({ parts_number }) => {
      dispatch(parts.actions.updateCount(parts_number));
    },
    [dispatch],
  );

  return (
    <div className="live-parts-wrapper">
      <ActionCable onReceived={onReceived} />
      <CarOutlined />
      <Typography.Title level={3}>{t('live-parts', { count })}</Typography.Title>
    </div>
  );
};

export default LiveParts;
