import { List } from 'antd';
import PropTypes from 'prop-types';

import ImageCard from 'components/atoms/ImageCard';

const ImagesList = ({ grid, dataSource, loading, baseUrl, brand = false }) => (
  <List
    grid={grid}
    dataSource={dataSource}
    renderItem={(item) => (
      <List.Item>
        <ImageCard imageUrl={item.image_url} imageName={item.name} url={`${baseUrl}${item.id}`} brand={brand} />
      </List.Item>
    )}
    loading={loading}
    style={{ alignItems: 'stretch' }}
  />
);

ImagesList.PropType = {
  grid: PropTypes.object,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  baseUrl: PropTypes.string,
};

export default ImagesList;
