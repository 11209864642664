import { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import parts from 'redux/parts';
import cart from 'redux/cart';
import user from 'redux/user';

const useCart = () => {
  const dispatch = useDispatch();
  const cartItems = useRef({});
  const currentUser = useSelector(user.selectors.getCurrentUser);
  const cartLoader = useSelector(cart.selectors.getLoadingState);
  const cartAlert = useSelector(cart.selectors.getAlert);
  const cartTotal = useSelector(cart.selectors.getCartTotal);

  const updateRecords = useCallback(
    (record) => {
      if (record.in_cart.quantity === '0') delete cartItems.current[record.id];
      else
        cartItems.current[record.id] = {
          part_id: record.id,
          id: record.id,
          price: Math.floor(
            currentUser.show_promotions && record['on_promotion?']
              ? record.price_and_promotion?.promotion
              : record.price,
          ),
          quantity: record.in_cart.quantity,
          in_cart: record.in_cart,
        };

      dispatch(parts.actions.updateRecords(record));
    },
    [dispatch, currentUser],
  );

  const onSubmit = useCallback(() => {
    const newCartItems = Object.values(cartItems.current);
    if (newCartItems.length > 0) dispatch(cart.thunks.addItems({ items: newCartItems, loader: true }));
    else
      dispatch(
        cart.actions.setAlert({ message: 'You need to select some items to be added to your cart', type: 'error' }),
      );
  }, [dispatch]);

  const onModalClose = useCallback(() => {
    dispatch(cart.actions.setAlert(null));
  }, [dispatch]);

  const resetCartItems = useCallback(() => {
    cartItems.current = {};
  }, []);

  return {
    onSubmit,
    updateRecords,
    onModalClose,
    resetCartItems,
    cartItems: cartItems.current,
    cartLoader,
    cartAlert,
    cartTotal,
  };
};

export default useCart;
