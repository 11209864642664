import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import startCase from 'lodash/startCase';

import user from 'redux/user';
import Section from 'components/molecules/Section';
import Table from 'components/molecules/Table';

import './index.scss';

const MyAccount = () => {
  const dispatch = useDispatch();
  const invoices = useSelector(user.selectors.getRecordsState);
  const loading = useSelector(user.selectors.getLoadingState);
  const total = useSelector(user.selectors.getRecordsTotal);
  const report = useSelector(user.selectors.getUserReport);
  const invoiceLoader = useSelector(user.selectors.getInvoiceLoadingState);
  const reportLoader = useSelector(user.selectors.getReportLoadingState);
  const [reportColumns, setReportColumns] = useState([]);

  const { t } = useTranslation(['common', 'user-report', 'table-fields']);

  useEffect(() => {
    dispatch(user.thunks.fetchUserInvoice({ params: { page: 1 } }));
    dispatch(user.thunks.fetchUserReport());
  }, [dispatch]);

  useEffect(() => {
    if (report?.months) {
      setReportColumns([
        {
          title: t(`user-report:id`),
          dataIndex: 'id',
          render: (value) => t(`user-report:${value}`),
          fixed: true,
          width: 120,
          align: 'center',
          showAsTitle: true,
        },
        ...report.months.map((month) => {
          return {
            title: month,
            dataIndex: month,
            width: 100,
            render: (value) => value?.toLocaleString() || 0,
            align: 'center',
          };
        }),
      ]);
    }
  }, [report, t]);

  const downloadPdf = ({ id, type }) => () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(user.thunks.fetchInvoice({ id, params: { type: startCase(type) } }));
  };

  const columns = [
    {
      title: 'date',
      dataIndex: 'created_at',
      type: 'dateTime',
    },
    {
      title: 'transaction',
      dataIndex: 'number',
      render: (value, record) => (
        <React.Fragment>
          {`${t(record.type)} ${record.method ? `(${t(record.method)}) ` : ''}: `}
          <Button className="button-link" type="link" onClick={downloadPdf(record)}>
            {value}
          </Button>
        </React.Fragment>
      ),
    },
    {
      title: 'order-total',
      dataIndex: 'total',
      valueLabel: 'currency',
    },
    {
      title: 'old-balance',
      dataIndex: 'client_old_balance',
      className: 'custom-font-weight',
    },
    {
      title: 'new-balance',
      dataIndex: 'client_new_balance',
      className: 'custom-td custom-font-weight',
    },
  ];
  const getReportTableTitle = useCallback(() => t(`user-report:summary`), [t]);

  const onPageChange = useCallback(
    ({ current, pageSize }) => {
      dispatch(user.thunks.fetchUserInvoice({ params: { page: current, per_page: pageSize } }));
      window.scrollTo(0, 0);
    },
    [dispatch],
  );

  return (
    <Spin size="large" spinning={invoiceLoader}>
      <Section title={t('your-account')}>
        <Table
          columns={reportColumns}
          dataSource={report.values || []}
          bordered
          pagination={false}
          scroll={{ x: 500 }}
          rowKey="id"
          className="report-table"
          title={getReportTableTitle}
          loading={reportLoader}
          header={t(`user-report:summary`)}
        />
        <Table
          columns={columns}
          dataSource={invoices}
          loading={loading}
          rowKey="number"
          localePath="table-fields"
          pagination={{ total, showSizeChanger: true, hideOnSinglePage: false }}
          showListGird={false}
          onChange={onPageChange}
        />
      </Section>
    </Spin>
  );
};

export default MyAccount;
