import { createAsyncThunk } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import UserAPI from './services';
import { history } from 'router';
import { addToLocalStorage } from 'utils/localStorageHelpers';
import { downloadFile } from 'utils/fileHelper';

export const signin = createAsyncThunk('user/login', async (user, { rejectWithValue }) => {
  try {
    const response = await UserAPI.login(user);
    const authHeaders = pick(response.headers, ['client', 'expiry', 'token', 'uid']);

    if (!isEmpty(authHeaders)) {
      addToLocalStorage('authHeaders', authHeaders);
      addToLocalStorage('showWelcomeMsg', true);
      addToLocalStorage('currentUser', response.data?.client);
      if (response.data.client.needs_two_factor_auth) {
        addToLocalStorage('twoFactorAuth', true);
        history.push('/clients/two_factor_authentication');
      } else history.push('/makers');
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchUser = createAsyncThunk('user/fetch', async () => {
  const response = await UserAPI.fetchUser();
  return response.data;
});

export const sendOTP = createAsyncThunk('user/sendOTP', async (_, { rejectWithValue }) => {
  try {
    const response = await UserAPI.sendAuthCode();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const verifyOTP = createAsyncThunk('user/verifyOTP', async (params, { rejectWithValue }) => {
  try {
    const response = await UserAPI.verify(params);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const resendOTP = createAsyncThunk('user/resendOTP', async (_, { rejectWithValue }) => {
  try {
    const response = await UserAPI.resendCode();
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchUserInvoice = createAsyncThunk('user/fetchUserInvoice', async ({ params }, { rejectWithValue }) => {
  try {
    const response = await UserAPI.fetchMyAccount(params);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchUserReport = createAsyncThunk('user/fetchUserReport', async (_, { rejectWithValue }) => {
  try {
    const response = await UserAPI.fetchUserReport();

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchInvoice = createAsyncThunk('user/downloadInvoice', async ({ id, params }, { rejectWithValue }) => {
  try {
    const response = await UserAPI.downloadInvoice({ id, params });
    downloadFile(response.data, `${id}.pdf`, true);

    return;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
export const fetchOrder = createAsyncThunk('user/downloadOrder', async ({ id, params }, { rejectWithValue }) => {
  try {
    const response = await UserAPI.downloadOrder({ id, params });
    downloadFile(response.data, `${id}.pdf`, true);

    return;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
