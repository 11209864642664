import React from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import './index.scss';

const Cell = ({ column, value, record, t }) => {
  const renderCell = () => {
    switch (column.type) {
      case 'dateTime':
        return `${t(column.valueLabel) || ''} ${format(new Date(value), 'dd-MM-yyyy, HH:mm:ss')}`;
      case 'link':
        return (
          <React.Fragment>
            {t(column.valueLabel)}
            <Link
              className="table-link"
              to={typeof column.to === 'function' ? column.to(record) : column.to}
              target="_blank"
            >
              {value}
            </Link>
          </React.Fragment>
        );
      case 'images':
        return (
          <div className="images-wrapper">
            {value.map((image) => (
              <Image src={image} key={image} className="image-icon" draggable={false} />
            ))}
          </div>
        );
      default:
        return `${t(column.valueLabel) || ''} ${
          typeof value === 'number' ? Math.floor(value)?.toLocaleString() : value
        }`;
    }
  };

  return renderCell();
};

Cell.PropType = {
  column: PropTypes.object,
  value: PropTypes.string,
  record: PropTypes.object,
  t: PropTypes.func,
};
export default Cell;
