import { Route, Switch, Redirect } from 'react-router';
import { ConfigProvider, Result } from 'antd';
import { useDispatch } from 'react-redux';

import DefaultLayout from 'layouts/DefaultLayout';
import { loadFromLocalStorage } from 'utils/localStorageHelpers';
import user from 'redux/user';

import MyAccount from 'screens/MyAccount';
import Search from 'screens/Search';
import Makers from 'screens/Makers';
import Models from 'screens/Models';
import Brands from 'screens/Brands';
import Categories from 'screens/Categories';
import Parts from 'screens/Parts';
import ListPrices from 'screens/ListPrices';
import Promotions from 'screens/Promotions';
import MyOrders from 'screens/MyOrders';
import Ads from 'screens/Ads';

import Cart from 'screens/Cart';

const PrivateRoutes = () => {
  const authHeaders = loadFromLocalStorage('authHeaders');
  const twoFactorAuth = loadFromLocalStorage('twoFactorAuth');
  const currentUser = loadFromLocalStorage('currentUser');
  const dispatch = useDispatch();

  if (!authHeaders) {
    dispatch(user.actions.setErrors('You need to sign in or sign up before continuing.'));
    return <Redirect push to="/clients/sign_in" />;
  } else if (twoFactorAuth) {
    return <Redirect push to="/clients/two_factor_authentication" />;
  }

  return (
    <ConfigProvider direction="rtl">
      <DefaultLayout>
        <Switch>
          <Route path="/makers" component={Makers} exact />
          <Route path="/models" component={Models} exact />
          <Route path="/brands" component={Brands} exact />
          <Route path="/categories" component={Categories} exact />
          <Route path="/parts" component={Parts} exact />
          <Route path="/search_by_code" component={Search} exact />
          {currentUser?.show_promotions && <Route path="/promotions" component={Promotions} exact />}
          {currentUser?.show_list_prices && <Route path="/list_prices" component={ListPrices} exact />}
          <Route path="/my_account" component={MyAccount} exact />
          <Route path="/cart" component={Cart} exact />
          <Route path="/my_orders" component={MyOrders} exact />
          <Route path="/ads" component={Ads} exact />
          <Route path="*" component={() => <Result status="404" title="عذرا ، الصفحة التي زرتها غير موجودة" />} />
        </Switch>
      </DefaultLayout>
    </ConfigProvider>
  );
};

export default PrivateRoutes;
