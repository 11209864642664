import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.brand,
  (brand) => brand.loading,
);

const getMetaData = createSelector(
  (state) => state?.brand,
  (brand) => brand.meta,
);

const selectors = {
  getLoadingState,
  getMetaData,
  ...adapter.getSelectors((state) => state.brand),
};

export default selectors;
