import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoutes from 'router/PrivateRoutes';
import PublicRoutes from 'router/PublicRoutes';

export const history = createBrowserHistory();

history.listen((location) => {
  const tracker = window.ga.getAll()[0];
  if (tracker && tracker.set && tracker.send) {
    tracker.set('page', location.pathname + location.search);
    tracker.send('pageview');
  }
});

const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <Route
      render={() => (
        <Switch>
          <Route path="/clients" component={PublicRoutes} />
          <Route path="/" component={PrivateRoutes} />
        </Switch>
      )}
    />
  );
};

export default Router;
