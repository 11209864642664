export const dropDown = {
  type: 'select',
  label: 'go-to-other-models',
  optionsEntitiy: {
    sliceidentifier: 'model',
    accessor: 'id',
    label: 'name',
    params: {
      show_all: true,
    },
  },
  identifier: 'model_id',
  dynamic: true,
};
