import axios from 'axios';
import assign from 'lodash/assign';
import { loadFromLocalStorage, addToLocalStorage } from 'utils/localStorageHelpers';
import store from 'redux/store';
import user from 'redux/user';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const injectAuthHeaders = (request) => {
  const authHeaders = loadFromLocalStorage('authHeaders');
  assign(request.headers, authHeaders);
  return request;
};

axios.interceptors.request.use(injectAuthHeaders);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      addToLocalStorage('unauthorized', true);
      store.dispatch(user.actions.setErrors('login again'));
    }
    return Promise.reject(error);
  },
);

export default axios;
