import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.parts,
  (parts) => parts.loading,
);

const getMetaData = createSelector(
  (state) => state?.parts,
  (parts) => parts.meta,
);

const getErrors = createSelector(
  (state) => state?.parts,
  (parts) => parts.errors,
);

const getCount = createSelector(
  (state) => state?.parts,
  (parts) => parts.count,
);

const selectors = {
  getLoadingState,
  getMetaData,
  getErrors,
  getCount,
  ...adapter.getSelectors((state) => state.parts),
};

export default selectors;
