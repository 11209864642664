import { useState, useCallback, Fragment } from 'react';
import { Button, Drawer, Alert, Typography } from 'antd';
import { PoweroffOutlined, MenuOutlined } from '@ant-design/icons';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import NavMenu from 'components/molecules/NavMenu';
import LiveParts from './components/LiveParts';

import './index.scss';

const TopHeader = ({ logout, errors }) => {
  const [open, setOpen] = useState(false);
  const isWide = useMedia('(min-width: 1201px)');
  const { t } = useTranslation(['header', 'alert']);

  const onToggle = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <Fragment>
      <div className="site-header">
        {isWide && <NavMenu mode="horizontal" theme="dark" overflowedIndicator={false} />}
        <div className="custom-menu-item">
          <Button type="primary" icon={<PoweroffOutlined />} onClick={logout}>
            {t('header:logout')}
          </Button>
          {!isWide && (
            <Fragment>
              <Button type="primary" onClick={onToggle} icon={<MenuOutlined />} />
              <Drawer visible={open} placement="left" onClose={onToggle}>
                <NavMenu onClick={onToggle} mode="inline" className="sider-menu" />
              </Drawer>
            </Fragment>
          )}
        </div>
      </div>
      {errors ? (
        <Alert
          type="error"
          message={
            <Typography.Title level={3} type="danger">
              {t(`alert:${errors}`)}
            </Typography.Title>
          }
          className="logout-alert"
        />
      ) : (
        <LiveParts />
      )}
    </Fragment>
  );
};

TopHeader.PropType = {
  logout: PropTypes.func,
};

export default TopHeader;
