import { Typography } from 'antd';
import PropTypes from 'prop-types';

import './index.scss';

const Section = ({ title, subTitle, children }) => (
  <div className="section-wrapper">
    <Typography.Title strong>{title}</Typography.Title>
    {subTitle && (
      <Typography.Title level={3} strong>
        {subTitle}
      </Typography.Title>
    )}
    {children}
  </div>
);

Section.PropType = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.element,
};

export default Section;
