import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

import './index.scss';

const ButtonSection = ({ submitTitle, cancelTitle, confirmText, cancelText, okText, onSubmit, onCancel, disabled }) => (
  <div className="buttons-wrapper">
    <Button type="primary" onClick={onSubmit} disabled={disabled}>
      {submitTitle}
    </Button>
    <Popconfirm onConfirm={onCancel} title={confirmText} cancelText={cancelText} okText={okText}>
      <Button type="primary" danger disabled={disabled}>
        {cancelTitle}
      </Button>
    </Popconfirm>
  </div>
);

ButtonSection.PropType = {
  submitTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ButtonSection;
