import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import model from 'redux/model';
import Section from 'components/molecules/Section';
import ImagesList from 'components/molecules/ImagesList';

const Models = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const params = useRef(queryString.parse(location.search));
  const models = useSelector(model.selectors.selectAll);
  const loading = useSelector(model.selectors.getLoadingState);

  useEffect(() => {
    dispatch(model.thunks.fetch({ ...params.current, show_all: true, reset: true }));
  }, [dispatch]);

  return (
    <Section title={t('models')}>
      <ImagesList
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={models}
        loading={loading}
        baseUrl={`/parts?maker_id=${params.current.maker_id}&model_id=`}
      />
    </Section>
  );
};

export default Models;
