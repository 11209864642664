import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, List, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Section from 'components/molecules/Section';

import ads from 'redux/ads';
import './index.scss';

const grid = {
  gutter: 16,
};

const Ads = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const userAds = useSelector(ads.selectors.selectAll);
  const loading = useSelector(ads.selectors.getLoadingState);
  const { total_entries: total } = useSelector(ads.selectors.getMetaData);

  useEffect(() => {
    dispatch(ads.thunks.fetch());
  }, [dispatch]);

  const onPageChange = ({ current }) => {
    dispatch(ads.thunks.fetch({ page: current }));
  };

  return (
    <Section title={t('ads')}>
      <List
        grid={grid}
        dataSource={userAds}
        renderItem={(item) => (
          <List.Item>
            <Card bordered cover={<img alt="" src={item.image_url} className="card-main-img" />} className="ads-card">
              <Typography.Title level={3}>{item.body}</Typography.Title>
            </Card>
          </List.Item>
        )}
        loading={loading}
        className="ads-wrapper"
        pagination={{ total, onChange: onPageChange, hideOnSinglePage: true }}
      />
    </Section>
  );
};

export default Ads;
