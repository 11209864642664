import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.model,
  (model) => model.loading,
);

const getMetaData = createSelector(
  (state) => state?.model,
  (model) => model.meta,
);

const selectors = {
  getLoadingState,
  getMetaData,
  ...adapter.getSelectors((state) => state.model),
};

export default selectors;
