import { Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import user from 'redux/user';
import CustomForm from 'components/molecules/CustomForm';
import { fields } from './formField';

import login_cover from 'assets/icons/login-cover.png';

import './index.scss';

const Login = () => {
  const dispatch = useDispatch();
  const errors = useSelector(user.selectors.getErrors);

  const onLogin = (values) => {
    dispatch(user.thunks.signin(values));
  };

  return (
    <div>
      <div className="login-background" />
      <div className="login-form">
        <Image src={login_cover} preview={false} />
        <CustomForm
          fields={fields}
          submitLabel="Login"
          initialValues={{ remember: false }}
          validateStatus={errors && 'error'}
          error={errors}
          onSubmit={onLogin}
          inputStyle="normal"
        />
      </div>
    </div>
  );
};

export default Login;
