import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import selectors from './selectors';
import adapter from './adapter';

const slice = createSlice({
  name: 'ads',
  initialState: adapter.getInitialState({
    loading: false,
    meta: {},
  }),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetch.pending, (state, action) => {
        state.loading = true;
        adapter.removeAll(state);
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        const { show_all } = action.meta.arg || {};
        const ads = show_all ? action.payload : action.payload.ads;

        adapter.setAll(state, ads);
        state.meta = action.payload.meta;
        state.loading = false;
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

const ads = {
  slice,
  thunks,
  selectors,
};
export default ads;
