import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import * as actions from './actions';
import selectors from './selectors';
import adapter from './adapter';

const slice = createSlice({
  name: 'Parts',
  initialState: adapter.getInitialState({
    loading: false,
    meta: {},
    errors: '',
    count: 0,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(actions.updateRecords, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addCase(actions.updateCount, (state, action) => {
        state.count = action.payload;
      })
      .addCase(thunks.extractPartsInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = '';
      })
      .addCase(thunks.fetchPartsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload.parts_number;
      })
      .addCase(thunks.extractPartsInfo.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload?.errors || 'something went wrong';
      })
      .addMatcher(
        isAnyOf(
          thunks.filterParts.pending,
          thunks.fetchPromotions.pending,
          thunks.extractPartsInfo.pending,
          thunks.fetchPartsCount.pending,
        ),
        (state, action) => {
          state.loading = true;
          state.errors = '';
          adapter.removeAll(state);
        },
      )
      .addMatcher(isAnyOf(thunks.filterParts.fulfilled, thunks.fetchPromotions.fulfilled), (state, action) => {
        const { show_all, selectedItems = {} } = action.meta.arg;
        const parts = show_all ? action.payload : action.payload.parts;

        adapter.setAll(state, parts);
        Object.keys(selectedItems).forEach((key) => {
          adapter.updateOne(state, { id: key, changes: selectedItems[key] });
        });
        state.meta = action.payload.meta;
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(thunks.filterParts.rejected, thunks.fetchPromotions.rejected, thunks.fetchPartsCount.rejected),
        (state, action) => {
          state.loading = false;
          state.errors = action.payload?.errors;
          adapter.removeAll(state);
        },
      );
  },
});

const parts = {
  slice,
  thunks,
  actions,
  selectors,
};
export default parts;
