import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { CarOutlined } from '@ant-design/icons';

import parts from 'redux/parts';

const WelcomeModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation('common');
  const count = useSelector(parts.selectors.getCount);

  useEffect(() => {
    dispatch(parts.thunks.fetchPartsCount());
  }, [dispatch]);

  const onClose = () => {
    setVisible(false);
    localStorage.removeItem('showWelcomeMsg');
  };

  return (
    <Modal footer={null} visible={visible} onCancel={onClose}>
      <Result icon={<CarOutlined />} title={`${t('welcome-first')} ${count} ${t('welcome-second')}`} />
    </Modal>
  );
};

export default WelcomeModal;
