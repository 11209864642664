import { Checkbox, List, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './index.scss';

const gridOptions = {
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 4,
};

const antIcon = <LoadingOutlined className="loading-icon" spin />;

const CheckboxGroup = ({ options, placeholder, loading, ...props }) => (
  <Checkbox.Group {...props} className="checkbox-group">
    <Typography.Text type="secondary">{placeholder}</Typography.Text>
    <List
      grid={gridOptions}
      dataSource={options}
      loading={{ indicator: <Spin indicator={antIcon} />, spinning: loading }}
      renderItem={(item) => (
        <List.Item>
          <Checkbox value={item.value}>{item.label}</Checkbox>
        </List.Item>
      )}
    />
  </Checkbox.Group>
);

CheckboxGroup.PropType = {
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
};
export default CheckboxGroup;
