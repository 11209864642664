import { memo } from 'react';
import { Checkbox, Form, Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useDynamicField from './hooks/useDynamicField';
import AsyncSelect from 'components/molecules/AsynSelect';
import CheckboxGroup from 'components/molecules/CheckboxGroup';

const Field = ({
  type,
  placeholder,
  customValue,
  staticOptions,
  inputStyle,
  dynamic,
  filteredState,
  optionsEntitiy,
  value,
  identifier,
  onChange,
  label,
  localePath = '',
  ...props
}) => {
  const { t } = useTranslation(localePath);
  const localizedPlaceHolder = t(placeholder);
  const [options, nextPage, loading, asyncFetch] = useDynamicField({
    filteredState,
    optionsEntitiy,
    onChange,
    identifier,
  });
  const entityOptions = dynamic ? options : staticOptions;

  const renderFieldItem = () => {
    switch (type) {
      case 'password':
        return (
          <Input.Password
            type="password"
            placeholder={localizedPlaceHolder}
            value={value}
            onChange={(e) => onChange(identifier, e.target.value)}
          />
        );
      case 'input':
        return (
          <Input
            placeholder={localizedPlaceHolder}
            value={value}
            onChange={(e) => onChange(identifier, e.target.value)}
          />
        );
      case 'number':
        return (
          <InputNumber placeholder={localizedPlaceHolder} value={value} onChange={(e) => onChange(identifier, e)} />
        );
      case 'checkbox':
        return (
          <Checkbox checked={value} onChange={(e) => onChange(identifier, e.target.checked)}>
            {customValue}
          </Checkbox>
        );
      case 'select':
        return (
          <AsyncSelect
            placeholder={localizedPlaceHolder}
            dynamic={dynamic}
            options={entityOptions}
            asyncFetch={asyncFetch}
            nextPage={nextPage}
            value={value}
            onChange={(e) => onChange(identifier, e)}
            optionsEntitiy={optionsEntitiy}
            loading={loading}
          />
        );
      case 'checkboxGroup':
        return (
          <CheckboxGroup
            options={entityOptions}
            loading={loading}
            placeholder={localizedPlaceHolder}
            value={value}
            onChange={(e) => onChange(identifier, e)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form.Item name={identifier} label={t(label)} {...props} className={inputStyle !== 'normal' ? 'custom-input' : ''}>
      {renderFieldItem()}
    </Form.Item>
  );
};

Field.PropType = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customValue: PropTypes.string,
  staticOptions: PropTypes.arrayOf(PropTypes.object),
  inputStyle: PropTypes.string,
  dynamic: PropTypes.bool,
  filteredState: PropTypes.object,
  optionsEntitiy: PropTypes.object,
  identifier: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  localePath: PropTypes.string,
};

export default memo(Field);
