import { createAsyncThunk } from '@reduxjs/toolkit';
import AdsAPI from './services';

export const fetch = createAsyncThunk('ads/fetchAll', async (params, { rejectWithValue }) => {
  try {
    const response = await AdsAPI.fetchAll(params);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
