import axios from 'utils/requestHelper';

const fetchAll = (params) => axios.get('v1/clients/carts', { params });
const submit = (data) => axios.post('v1/clients/carts', { invoice: { parts: data } });
const addItems = (items) => axios.put('v1/clients/carts/add', items);
const deleteItems = (items) => axios.put('v1/clients/carts/remove', items);

const CartAPI = {
  fetchAll,
  submit,
  addItems,
  deleteItems,
};

export default CartAPI;
