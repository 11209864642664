import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import brand from 'redux/brand';
import Section from 'components/molecules/Section';
import ImagesList from 'components/molecules/ImagesList';

const Brands = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const brands = useSelector(brand.selectors.selectAll);
  const loading = useSelector(brand.selectors.getLoadingState);

  useEffect(() => {
    dispatch(brand.thunks.fetch({ show_all: true }));
  }, [dispatch]);

  return (
    <Section title={t('brands')}>
      <ImagesList
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={brands}
        loading={loading}
        baseUrl={`/parts?brand_id=`}
        brand={true}
      />
    </Section>
  );
};

export default Brands;
