import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from 'router';
import user from './user';
import model from './model';
import maker from './maker';
import brand from './brand';
import parts from './parts';
import category from './category';
import cart from './cart';
import invoice from './invoice';
import ads from './ads';

const rootReducer = () =>
  combineReducers({
    router: connectRouter(history),
    user: user.slice.reducer,
    model: model.slice.reducer,
    maker: maker.slice.reducer,
    brand: brand.slice.reducer,
    parts: parts.slice.reducer,
    category: category.slice.reducer,
    cart: cart.slice.reducer,
    invoice: invoice.slice.reducer,
    ads: ads.slice.reducer,
  });

//Keep all slices refernece in one place to access them in dynamicFields directly
export const slices = {
  model,
  maker,
  brand,
  parts,
};

export default rootReducer;
