import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import parts from 'redux/parts';

import Section from 'components/molecules/Section';
import Table from 'components/molecules/Table';
import PurchaseButton from 'components/molecules/PurchaseButton';
import CartModal from 'components/molecules/CartModal';
import useCart from 'hooks/useCart';

import newIcon from 'assets/icons/new.png';

import './index.scss';

const Promotions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const promotionsParts = useSelector(parts.selectors.selectAll);
  const loading = useSelector(parts.selectors.getLoadingState);
  const { total_entries, current_page } = useSelector(parts.selectors.getMetaData);
  const { onSubmit, updateRecords, onModalClose, cartItems, cartLoader, cartAlert, cartTotal } = useCart();

  useEffect(() => {
    dispatch(parts.thunks.fetchPromotions({ params: { page: 1 } }));
  }, [dispatch]);

  const onChange = ({ current }) => {
    dispatch(parts.thunks.fetchPromotions({ params: { page: current }, selectedItems: cartItems }));
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      title: 'index',
      dataIndex: 'id',
      render: (_, __, index) => (current_page - 1) * 10 + index + 1,
      width: '2%',
    },
    {
      title: 'product-name',
      dataIndex: 'arabic_name',
      render: (text, record) => (
        <React.Fragment>
          {text} {record.newly_updated && <img src={newIcon} alt="New" />}
        </React.Fragment>
      ),
      width: '20%',
    },
    {
      title: 'model',
      dataIndex: 'show_model_names',
    },
    {
      title: 'brand',
      dataIndex: 'brand_image',
      render: (value, record) => <img src={value} alt={record.brand_name} className="custom-table-img" />,
    },
    {
      title: 'country',
      dataIndex: 'country_name',
    },
    {
      title: 'remark',
      dataIndex: 'remark',
    },
    {
      title: 'old-price',
      dataIndex: 'price_and_promotion',
      render: (value) => <s>{Math.floor(value?.price)?.toLocaleString()}</s>,
    },
    {
      title: 'new-price',
      dataIndex: 'price_and_promotion',
      render: (value) => Math.floor(value?.promotion)?.toLocaleString(),
    },
    {
      title: 'quantity',
      dataIndex: ['in_cart', 'quantity'],
      isEditable: true,
      type: 'number',
    },
    {
      title: 'total',
      dataIndex: 'total',
      render: (_, record) => {
        return (
          (
            Math.floor(record.price_and_promotion?.promotion || record.price) * record.in_cart.quantity
          )?.toLocaleString() || 0
        );
      },
    },
    {
      title: 'images',
      dataIndex: 'images',
      type: 'images',
      width: '20%',
    },
    {
      title: 'code',
      dataIndex: 'code',
    },
  ];

  return (
    <div title={t('promotions')}>
      <CartModal alert={cartAlert} onCancel={onModalClose} total={cartTotal} />
      <PurchaseButton buttonTitle={t('add-to-cart')} sideLabel={t('add-to-cart-side-label')} onClick={onSubmit} />
      <Table
        columns={columns}
        dataSource={promotionsParts}
        updateDataSource={updateRecords}
        loading={loading || cartLoader}
        rowKey="id"
        localePath="table-fields"
        pagination={{ total: total_entries, pageSize: 1000 }}
        onChange={onChange}
      />
    </div>
  );
};

export default Promotions;
