import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { menuItems } from './menuItems';
import user from 'redux/user';

const NavMenu = (props) => {
  const location = useLocation();
  const currentUser = useSelector(user.selectors.getCurrentUser);
  const { t } = useTranslation('header');

  const renderMenuItems = (item) => {
    switch (item.type) {
      case 'subMenu':
        return (
          <Menu.SubMenu title={t(item.title)} icon={item.icon} key={item.title}>
            {item.items.map((subMenuItem) => renderMenuItems(subMenuItem))}
          </Menu.SubMenu>
        );
      default:
        return (
          <Menu.Item key={item.to} icon={item.icon}>
            <Link to={item.to}>{t(item.title)}</Link>
          </Menu.Item>
        );
    }
  };
  return (
    <Menu selectedKeys={[`${location.pathname}${location.search}`]} {...props}>
      {menuItems
        .filter((item) => (typeof item.hide === 'function' ? !item.hide(currentUser) : !item.hide))
        .map((item) => renderMenuItems(item))}
    </Menu>
  );
};

export default NavMenu;
