import { createAsyncThunk } from '@reduxjs/toolkit';
import MakerAPI from './services';

export const fetch = createAsyncThunk('maker/fetchAll', async (params, { rejectWithValue }) => {
  try {
    const response = await MakerAPI.fetchAll(params);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
