import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import selectors from './selectors';
import adapter from './adapter';

const slice = createSlice({
  name: 'maker',
  initialState: adapter.getInitialState({
    loading: false,
    meta: {},
  }),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetch.pending, (state, action) => {
        state.loading = true;
        adapter.removeAll(state);
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.makers);
        state.meta = action.payload.meta;
        state.loading = false;
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

const model = {
  slice,
  thunks,
  selectors,
};
export default model;
