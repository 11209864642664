import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Typography } from 'antd';
import { DoubleLeftOutlined } from '@ant-design/icons';

import user from 'redux/user';
import CustomForm from 'components/molecules/CustomForm';
import { fields } from './formFields';

import './index.scss';

const Authentication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['authentication-screen', 'alert']);
  const [disabled, setDisabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const intervalId = useRef(null);
  const errors = useSelector(user.selectors.getErrors);

  useEffect(() => {
    disbaleButton();
    dispatch(user.thunks.sendOTP());
  }, [dispatch]);

  const verify = (values) => {
    dispatch(user.thunks.verifyOTP(values))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        localStorage.removeItem('twoFactorAuth');
        clearInterval(intervalId.current);
        history.replace('/makers');
      })
      .catch((rejectedValueOrSerializedError) => {});
  };

  const resendCode = () => {
    disbaleButton();
    dispatch(user.thunks.resendOTP());
  };

  const disbaleButton = () => {
    setDisabled(true);
    const currIntervalId = setInterval(() => {
      setTimer((timer) => {
        intervalId.current = currIntervalId;
        if (timer > 0) return timer - 1;
        else {
          clearInterval(currIntervalId);
          setDisabled(false);
          return 30;
        }
      });
    }, 1000);
  };

  const onExit = () => {
    localStorage.removeItem('authHeaders');
    localStorage.removeItem('twoFactorAuth');
    dispatch(user.actions.setErrors(''));
    history.replace('/clients/sign_in');
  };

  return (
    <div className="auth-form">
      <Button type="link" onClick={onExit} icon={<DoubleLeftOutlined />}>
        {t('authentication-screen:exit')}
      </Button>
      <Typography.Title strong>{t('authentication-screen:title')}</Typography.Title>
      <CustomForm
        fields={fields}
        submitLabel={t('authentication-screen:login')}
        onSubmit={verify}
        inputStyle="normal"
        validateStatus={errors && 'error'}
        error={t(`alert:${errors}`)}
      />
      {disabled && (
        <Typography.Title className="custom-text" level={3} strong>{`${t('resend-title')} ${timer} ${t(
          'authentication-screen:second',
        )}`}</Typography.Title>
      )}
      <Button disabled={disabled} onClick={resendCode} className="custom-button">
        {t('authentication-screen:resend-code')}
      </Button>
    </div>
  );
};

export default Authentication;
