import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';

import invoice from 'redux/invoice';
import user from 'redux/user';

import Section from 'components/molecules/Section';
import Table from 'components/molecules/Table';

const MyOrders = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const orders = useSelector(invoice.selectors.selectAll);
  const { total_entries: total } = useSelector(invoice.selectors.getMetaData);
  const loading = useSelector(invoice.selectors.getLoadingState);
  const invoiceLoader = useSelector(user.selectors.getInvoiceLoadingState);

  useEffect(() => {
    dispatch(invoice.thunks.fetch());
  }, [dispatch]);

  const onPageChange = ({ current }) => {
    dispatch(invoice.thunks.fetch({ page: current }));
    window.scrollTo(0, 0);
  };

  const downloadPdf = ({ id }) => () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(user.thunks.fetchOrder({ id, params: { type: 'Invoice' } }));
  };
  const tableColumns = [
    {
      title: 'date',
      dataIndex: 'created_at',
      type: 'dateTime',
    },
    {
      title: 'invoice',
      dataIndex: 'number',
      render: (value, record) => (
        <Button className="button-link" type="link" onClick={downloadPdf(record)}>
          {value}
        </Button>
      ),
    },
    {
      title: 'order-total',
      dataIndex: 'total',
      valueLabel: 'currency',
    },
    {
      title: 'number-of-items',
      dataIndex: 'items',
      render: (value) => value.length,
    },
  ];

  return (
    <Spin size="large" spinning={invoiceLoader}>
      <Section title={t('your-orders')}>
        <Table
          columns={tableColumns}
          dataSource={orders}
          loading={loading}
          rowKey={'id'}
          localePath="table-fields"
          pagination={{ total }}
          onChange={onPageChange}
          showListGird={false}
        />
      </Section>
    </Spin>
  );
};

export default MyOrders;
