import React, { useState, useEffect } from 'react';
import { Button, Modal, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const CartModal = ({ alert, total, onCancel, hideExtraButtons = false }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation(['cart', 'alert']);
  const history = useHistory();

  useEffect(() => {
    if (alert) setVisible(true);
  }, [alert]);

  const onModalClose = ({ redirect }) => {
    setVisible(false);
    onCancel();
    if (redirect) history.push('/cart');
  };

  return (
    <Modal centered visible={visible} onCancel={onModalClose} footer={null}>
      <Result
        status={alert?.type}
        title={t(`alert:${alert?.message}`, { total })}
        subTitle={alert?.subtitle ? t(`cart:${alert?.subtitle}`) : ''}
        extra={
          alert?.type === 'success' &&
          !hideExtraButtons && (
            <React.Fragment>
              <Button key="shop" onClick={onModalClose}>
                {t('cart:continue shopping')}
              </Button>
              <Button type="primary" key="cart" onClick={() => onModalClose({ redirect: true })}>
                {t('cart:go to cart')}
              </Button>
            </React.Fragment>
          )
        }
      />
    </Modal>
  );
};

export default CartModal;
