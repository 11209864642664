import { createAsyncThunk } from '@reduxjs/toolkit';
import CartAPI from './services';

const reduceData = (data) => {
  return data.reduce((acc, item) => {
    const newRecord = {
      id: item.id,
      quantity: item.quantity,
      price: item.price,
      part_id: item.part.id,
      arabic_name: item.part.arabic_name,
      code: item.part.code,
      part_number: item.part.part_number,
      part_images: item.part.images,
      brand: item.part.brand_name,
      country: item.part.country_name,
      remark: item.part.remark,
    };
    return [...acc, newRecord];
  }, []);
};

export const fetch = createAsyncThunk('cart/fetchAll', async (params, { rejectWithValue }) => {
  try {
    const response = await CartAPI.fetchAll(params);
    if (params?.show_all) response.data = reduceData(response.data);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const addItems = createAsyncThunk('cart/addItems', async ({ items }, { rejectWithValue }) => {
  try {
    const data = new FormData();
    for (let i = 0; i < items.length; i++) {
      Object.keys(items[i]).map((key) => {
        return data.append(`cart_items[${i}][${key}]`, items[i][key]);
      });
    }
    const response = await CartAPI.addItems(data);
    response.data.cart_items = reduceData(response.data.cart_items);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const removeItems = createAsyncThunk('cart/removeItems', async ({ ids }, { rejectWithValue }) => {
  try {
    const response = await CartAPI.deleteItems({ cart_item_ids: ids });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const submitCart = createAsyncThunk('cart/submitCart', async (parts, { rejectWithValue }) => {
  try {
    const response = await CartAPI.submit(parts);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
