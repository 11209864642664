import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import selectors from './selectors';
import adapter from './adapter';

const slice = createSlice({
  name: 'model',
  initialState: adapter.getInitialState({
    loading: false,
    meta: {},
  }),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetch.pending, (state, action) => {
        state.loading = true;
        const { reset = false } = action.meta.arg;
        if (reset) adapter.removeAll(state);
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        const { reset = false, show_all } = action.meta.arg;
        const models = show_all ? action.payload : action.payload.models;

        if (reset) adapter.setAll(state, models);
        else adapter.upsertMany(state, models);

        state.meta = action.payload.meta;
        state.loading = false;
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        state.loading = false;
        adapter.removeAll(state);
      });
  },
});

const model = {
  slice,
  thunks,
  selectors,
};
export default model;
