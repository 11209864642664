import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import category from 'redux/category';
import Section from 'components/molecules/Section';
import ImagesList from 'components/molecules/ImagesList';

const grid = {
  gutter: 16,
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
  xxl: 4,
};

const Categories = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const categories = useSelector(category.selectors.selectAll);
  const loading = useSelector(category.selectors.getLoadingState);

  useEffect(() => {
    dispatch(category.thunks.fetch({ show_all: true }));
  }, [dispatch]);

  return (
    <Section title={t('categories')}>
      <ImagesList grid={grid} dataSource={categories} loading={loading} baseUrl={`/parts?category_id=`} />
    </Section>
  );
};

export default Categories;
