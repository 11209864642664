import { createAsyncThunk } from '@reduxjs/toolkit';
import PartsAPI from './services';
import { downloadFile } from 'utils/fileHelper';

export const filterParts = createAsyncThunk('parts/fetch', async ({ params }) => {
  const response = await PartsAPI.fetchByFilters(params);
  return response.data;
});

export const extractPartsInfo = createAsyncThunk('parts/extract', async (params, { rejectWithValue }) => {
  try {
    const response = await PartsAPI.downloadParts(params);
    const open = params.format === 'pdf' ? true : false;
    downloadFile(response.data, `list_prices.${params.format}`, open);

    return;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const fetchPromotions = createAsyncThunk('parts/fetch', async ({ params }) => {
  const response = await PartsAPI.fetchPartsOnPromotions(params);

  return response.data;
});

export const fetchPartsCount = createAsyncThunk('parts/count', async () => {
  const response = await PartsAPI.fetchCount();

  return response.data;
});
