import { Table as AntdTable } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import Cell from 'components/atoms/Cell';
import EditableCell from 'components/atoms/EditableCell';
import ListView from './ListView';
import './index.scss';

const Table = ({
  columns,
  dataSource,
  updateDataSource,
  pagination = false,
  onChange,
  localePath = '',
  rowKey,
  ...props
}) => {
  const { t } = useTranslation(localePath);
  const isWide = useMedia('(min-width: 576px)');

  const tableColumns = columns.map((col) => {
    const renderedCol = {
      render: (value = '', record) =>
        col.isEditable ? (
          <EditableCell column={col} record={record} updateRecord={updateDataSource} value={value} />
        ) : (
          <Cell column={col} value={value} record={record} t={t} />
        ),
      ...col,
      title: t(col.title),
      width: col.width,
    };
    return renderedCol;
  });

  return (
    <div className="custom-table">
      {isWide ? (
        <AntdTable
          columns={tableColumns}
          dataSource={dataSource}
          bordered={true}
          pagination={{ hideOnSinglePage: true, ...pagination }}
          onChange={onChange}
          scroll={{ x: 500, y: 500 }}
          rowKey={rowKey}
          {...props}
        />
      ) : (
        <ListView
          columns={tableColumns}
          dataSource={dataSource}
          pagination={pagination}
          localePath={localePath}
          rowKey={rowKey}
          {...props}
        />
      )}
    </div>
  );
};

Table.PropType = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  updateDataSource: PropTypes.func,
  pagination: PropTypes.object,
  onChange: PropTypes.func,
  localePath: PropTypes.string,
};

export default Table;
