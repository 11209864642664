import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.cart,
  (cart) => cart.loading,
);

const getAlert = createSelector(
  (state) => state.cart,
  (cart) => cart.alert,
);

const getCartTotal = createSelector(
  (state) => state.cart,
  (cart) => cart.total,
);

const selectors = {
  getLoadingState,
  getAlert,
  getCartTotal,
  ...adapter.getSelectors((state) => state.cart),
};

export default selectors;
