import {
  UnorderedListOutlined,
  BarcodeOutlined,
  PercentageOutlined,
  DollarOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  FileTextOutlined,
  BellOutlined,
} from '@ant-design/icons';

export const menuItems = [
  {
    type: 'subMenu',
    title: 'list-prices',
    icon: <UnorderedListOutlined />,
    items: [
      {
        title: 'makers',
        to: '/makers',
      },
      {
        title: 'brands',
        to: '/brands',
      },
      {
        title: 'categories',
        to: '/categories',
      },
    ],
  },
  {
    title: 'search-by-code',
    to: '/search_by_code',
    icon: <BarcodeOutlined />,
  },
  {
    title: 'special-offers',
    to: '/promotions',
    icon: <DollarOutlined />,
    hide: (currentUser) => !currentUser?.show_promotions,
  },
  {
    title: 'list-prices-percentage',
    to: '/list_prices',
    icon: <PercentageOutlined />,
    hide: (currentUser) => !currentUser?.show_list_prices,
  },
  {
    title: 'my-account',
    to: '/my_account',
    icon: <UserOutlined />,
  },
  {
    title: 'prev-orders',
    to: '/my_orders',
    icon: <FileTextOutlined />,
  },
  {
    title: 'ads',
    to: '/ads',
    icon: <BellOutlined />,
  },
  {
    title: 'cart',
    to: '/cart',
    icon: <ShoppingCartOutlined />,
  },
];
