import { useState } from 'react';
import { Alert } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { history } from 'router';
import Section from 'components/molecules/Section';
import CustomForm from 'components/molecules/CustomForm';
import parts from 'redux/parts';

import { searchByCode } from './formFields';

const itemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 6 },
};

const Search = () => {
  const { t } = useTranslation('search-screen');
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [code, setCode] = useState('');

  const onSearch = (values) => {
    setCode(values.part_code);
    setAlert(false);
    dispatch(parts.thunks.filterParts({ params: { type: 'code', ...values } }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const { total_entries } = originalPromiseResult?.meta;
        if (total_entries === 0) {
          setAlert(true);
        } else {
          const params = queryString.stringify({ type: 'code', ...values });
          history.push(`/parts?${params}`);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (rejectedValueOrSerializedError?.message !== 'Request failed with status code 401') setAlert(true);
      });
  };

  return (
    <Section title={t('code-title')} subTitle={t('code-sub-title')}>
      {alert && <Alert message={t('code-doesnt-exist', { code })} type="error" showIcon className="custom-alert" />}
      <CustomForm
        submitLabel={t('search')}
        fields={searchByCode}
        onSubmit={onSearch}
        itemLayout={itemLayout}
        localePath="search-screen"
      />
    </Section>
  );
};

export default Search;
