import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Spin } from 'antd';

import Section from 'components/molecules/Section';
import CustomForm from 'components/molecules/CustomForm';

import parts from 'redux/parts';

import { formFields } from './formFields';

const itemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const ListPrices = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['list-prices', 'alert']);
  const loading = useSelector(parts.selectors.getLoadingState);
  const errors = useSelector(parts.selectors.getErrors);

  const onExtract = (values) => {
    dispatch(parts.thunks.extractPartsInfo(values));
  };

  return (
    <Spin size="large" spinning={loading}>
      <Section title={t('list-prices:label')}>
        {errors && <Alert message={t(`alert:${errors}`)} type="error" showIcon className="custom-alert" />}
        <CustomForm
          submitLabel={t('list-prices:extract')}
          fields={formFields}
          onSubmit={onExtract}
          itemLayout={itemLayout}
          localePath="list-prices"
        />
      </Section>
    </Spin>
  );
};

export default ListPrices;
