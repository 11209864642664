import { Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './index.scss';

const PurchaseButton = ({ buttonTitle, sideLabel, onClick }) => (
  <div className="purchase-button">
    <Button type="primary" onClick={onClick}>
      {buttonTitle}
    </Button>
    <Typography.Text>
      <ArrowRightOutlined />
      {sideLabel}
    </Typography.Text>
  </div>
);

PurchaseButton.PropType = {
  buttonTitle: PropTypes.string,
  sideLabel: PropTypes.string,
  onClick: PropTypes.func,
};

export default PurchaseButton;
