import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.maker,
  (maker) => maker.loading,
);

const getMetaData = createSelector(
  (state) => state?.maker,
  (maker) => maker.meta,
);

const selectors = {
  getLoadingState,
  getMetaData,
  ...adapter.getSelectors((state) => state.maker),
};

export default selectors;
