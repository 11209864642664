import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.category,
  (category) => category.loading,
);

const getMetaData = createSelector(
  (state) => state?.category,
  (category) => category.meta,
);

const selectors = {
  getLoadingState,
  getMetaData,
  ...adapter.getSelectors((state) => state.category),
};

export default selectors;
