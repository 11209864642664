const customFormReducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case 'change':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default customFormReducer;
