export const columns = [
  {
    title: 'index',
    dataIndex: 'index',
    render: (_, __, index) => {
      return index + 1;
    },
    width: '2%',
  },
  {
    title: 'product-name',
    dataIndex: 'arabic_name',
    width: '20%',
  },
  {
    title: 'brand',
    dataIndex: 'brand',
  },
  {
    title: 'country',
    dataIndex: 'country',
  },
  {
    title: 'remark',
    dataIndex: 'remark',
  },
  {
    title: 'price',
    dataIndex: 'price',
  },
  {
    title: 'quantity',
    dataIndex: 'quantity',
    isEditable: true,
    type: 'number',
  },
  {
    title: 'total',
    dataIndex: 'total',
    render: (_, record) => {
      return (Math.floor(record.price) * record.quantity)?.toLocaleString() || 0;
    },
  },
  {
    title: 'code',
    dataIndex: 'code',
  },
  {
    title: 'images',
    dataIndex: 'part_images',
    type: 'images',
    width: '20%',
  },
];
