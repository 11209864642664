import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { subMonths, format, parse } from 'date-fns';
import ar from 'date-fns/locale/ar-SA';

import * as thunks from './thunks';
import * as actions from './actions';
import selectors from './selectors';
import adapter from './adapter';

function startOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getPrevMonths() {
  let prevMonths = [];
  for (var i = 0; i < 6; i += 1) {
    const date = startOfMonth(subMonths(new Date(), i));
    prevMonths.push(format(date, 'LLLL (uuuu)', { locale: ar }));
  }
  return prevMonths;
}

const slice = createSlice({
  name: 'User',
  initialState: adapter.getInitialState({
    loading: false,
    currentUser: {},
    errors: '',
    report: {},
    invoiceLoader: false,
    reportLoader: false,
    records: [],
    records_total: 0,
  }),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.signin.fulfilled, (state, action) => {
        state.currentUser = action.payload.client;
        state.errors = '';
        localStorage.removeItem('unauthorized');
      })
      .addCase(actions.setErrors, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(thunks.fetchUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      })
      .addCase(actions.signout, (state, action) => {
        state.currentUser = {};
        localStorage.removeItem('authHeaders');
        localStorage.removeItem('currentUser');
        state.errors = action.payload || '';
      })
      .addCase(thunks.fetchUserReport.pending, (state, action) => {
        state.reportLoader = true;
        state.report = {};
      })
      .addCase(thunks.fetchUserReport.fulfilled, (state, action) => {
        const report = action.payload;
        state.reportLoader = false;
        if (report) {
          state.report.payments = { id: 'payments' };
          state.report.invoices = { id: 'invoices' };
          state.report.months = getPrevMonths();
          for (const [key, value] of Object.entries(report)) {
            const month = format(parse(`01-${key}`, 'dd-MM-yyyy', new Date()), 'LLLL (uuuu)', { locale: ar });
            state.report.invoices[month] = value.invoices || 0;
            state.report.payments[month] = value.payments || 0;
          }
        }
        state.report.values = [state.report.invoices, state.report.payments];
      })
      .addCase(thunks.fetchUserReport.rejected, (state, action) => {
        state.reportLoader = false;
      })
      .addCase(thunks.fetchUserInvoice.pending, (state, action) => {
        state.loading = true;
        state.errors = '';
        state.records = [];
      })
      .addCase(thunks.fetchUserInvoice.fulfilled, (state, action) => {
        const { total_entries, results = [] } = action.payload;
        state.records = results;
        state.records_total = total_entries;
        state.loading = false;
      })
      .addCase(thunks.fetchUserInvoice.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.payload?.errors?.[0] || 'something went wrong';
      })
      .addMatcher(isAnyOf(thunks.fetchInvoice.pending, thunks.fetchOrder.pending), (state, action) => {
        state.invoiceLoader = true;
        state.errors = '';
      })
      .addMatcher(isAnyOf(thunks.fetchInvoice.fulfilled, thunks.fetchOrder.fulfilled), (state, action) => {
        state.invoiceLoader = false;
      })
      .addMatcher(isAnyOf(thunks.fetchInvoice.rejected, thunks.fetchOrder.rejected), (state, action) => {
        state.invoiceLoader = false;
        state.errors = action.payload?.errors?.[0] || 'something went wrong';
      })
      .addMatcher(
        isAnyOf(thunks.verifyOTP.fulfilled, thunks.sendOTP.fulfilled, thunks.resendOTP.fulfilled),
        (state, action) => {
          state.errors = '';
        },
      )
      .addMatcher(
        isAnyOf(thunks.signin.rejected, thunks.verifyOTP.rejected, thunks.sendOTP.rejected, thunks.resendOTP.rejected),
        (state, action) => {
          state.errors = action.payload.error || 'something went wrong';
        },
      );
  },
});

const user = {
  slice,
  thunks,
  actions,
  selectors,
};
export default user;
