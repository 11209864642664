import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './index.scss';

const UserInfo = ({ name, phoneNumber, balance }) => {
  const { t } = useTranslation('user-info');

  return (
    <div className="user-info-wrapper">
      <div className="sub-info-wrapper">
        <Typography.Title level={3}>
          {t('user-name')} {name}
        </Typography.Title>
      </div>
      <div className="sub-info-wrapper">
        <Typography.Title level={3}>
          {t('user-phone')} {phoneNumber}
        </Typography.Title>
      </div>
      <div className="sub-info-wrapper">
        <Typography.Title level={3}>
          {t('user-balance')} {balance?.toLocaleString()}
        </Typography.Title>
      </div>
    </div>
  );
};

UserInfo.PropType = {
  name: PropTypes.string,
  phoneNumber: PropTypes.number,
  balance: PropTypes.number,
};
export default UserInfo;
