import { InputNumber, Input } from 'antd';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

const EditableCell = ({ column, record, updateRecord, value }) => {
  const onSave = (event) => {
    const updatedRecord = cloneDeep(record);
    set(updatedRecord, column.dataIndex, event?.target?.value || event);
    updateRecord(updatedRecord);
  };

  const renderEditableCell = () => {
    switch (column.type) {
      case 'number':
        return <InputNumber type={column.type} min={column.min || 0} defaultValue={value} onChange={onSave} />;
      default:
        return <Input type={column.type} defaultValue={value} onBlur={onSave} onPressEnter={onSave} />;
    }
  };
  return renderEditableCell();
};

EditableCell.PropType = {
  column: PropTypes.object,
  record: PropTypes.object,
  updateRecord: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EditableCell;
