import { useEffect, useCallback } from 'react';
import { Layout } from 'antd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';
import { createConsumer } from '@rails/actioncable';

import { loadFromLocalStorage } from 'utils/localStorageHelpers';

import Header from 'components/molecules/Header';
import UserInfo from 'components/molecules/UserInfo';
import user from 'redux/user';
import WelcomeModal from './components/WelcomeModal';

import './index.scss';

const DefaultLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(user.selectors.getCurrentUser);
  const errors = useSelector(user.selectors.getErrors);
  const showWelcomeMsg = loadFromLocalStorage('showWelcomeMsg');
  const authHeaders = loadFromLocalStorage('authHeaders');
  const cable = createConsumer(
    `${process.env.REACT_APP_CABLE_BASE_URL}?token=${authHeaders?.token}&client=${authHeaders?.client}&uid=${authHeaders?.uid}`,
  );

  useEffect(() => {
    dispatch(user.thunks.fetchUser());
  }, [dispatch, children]);

  const logout = useCallback(() => {
    dispatch(user.actions.signout());
    history.push('clients/sign_in');
  }, [dispatch, history]);

  return (
    <ActionCableProvider cable={cable}>
      <Layout className="site-layout">
        <Header logout={logout} errors={errors} />
        <UserInfo name={currentUser.full_name} balance={currentUser.balance} phoneNumber={currentUser.phone_1} />
        <Layout.Content>
          <TransitionGroup>
            <CSSTransition key={location} timeout={1000} classNames="fade">
              <div className="content-layout">{children}</div>
            </CSSTransition>
          </TransitionGroup>
        </Layout.Content>
        {showWelcomeMsg && <WelcomeModal />}
      </Layout>
    </ActionCableProvider>
  );
};

export default DefaultLayout;
