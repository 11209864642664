import { useCallback } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import './index.scss';

const AsynSelect = ({ options, placeholder, dynamic, nextPage, asyncFetch, loading, optionsEntitiy, ...props }) => {
  const onScroll = useCallback(
    (event) => {
      const { scrollTop, scrollHeight, offsetHeight } = event.target;
      if (scrollTop + offsetHeight >= scrollHeight && dynamic && nextPage) {
        event.target.scrollTo(0, scrollHeight);
        asyncFetch({ page: nextPage, reset: false });
      }
    },
    [dynamic, asyncFetch, nextPage],
  );

  const onSearch = useCallback(
    (value) => {
      if (dynamic && nextPage) asyncFetch({ query: value, page: 1 });
    },
    [asyncFetch, dynamic, nextPage],
  );

  return (
    <Select
      showSearch
      className="async-select"
      options={options}
      onPopupScroll={onScroll}
      optionFilterProp="label"
      onSearch={onSearch}
      filterOption={!(dynamic && nextPage)}
      placeholder={placeholder}
      loading={loading}
      {...props}
    />
  );
};

AsynSelect.PropType = {
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  dynamic: PropTypes.bool,
  nextPage: PropTypes.number,
  asyncFetch: PropTypes.func,
  loading: PropTypes.bool,
  optionsEntitiy: PropTypes.object,
};

export default AsynSelect;
