import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { slices } from 'redux/reducers';

const useDynamicField = ({ filteredState, optionsEntitiy = {}, onChange, identifier }) => {
  const prevFilteredState = useRef();
  const { sliceidentifier } = optionsEntitiy;
  const slice = slices[sliceidentifier];
  const dispatch = useDispatch();
  const values = useSelector(slice?.selectors?.selectAll || (() => {}));
  const options = values?.reduce(
    (acc, option) => [
      ...acc,
      {
        label: option[optionsEntitiy?.label || 'label'],
        value: option[optionsEntitiy?.accessor || 'value'],
      },
    ],
    [],
  );
  const { next_page: nextPage } = useSelector(slice?.selectors?.getMetaData || (() => {})) || {};
  const loading = useSelector(slice?.selectors?.getLoadingState || (() => {}));

  const asyncFetch = useCallback(
    (extraFilters) => {
      const newFilters = { ...filteredState, ...optionsEntitiy.params, ...extraFilters };
      if (!isEqual(prevFilteredState.current, newFilters) && slice?.thunks?.fetch) {
        if (newFilters.reset && filteredState) onChange(identifier, undefined);
        prevFilteredState.current = newFilters;
        dispatch(slice.thunks.fetch(newFilters));
      }
    },
    [dispatch, slice, filteredState, onChange, identifier, optionsEntitiy],
  );

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  return [options, nextPage, loading, asyncFetch];
};

export default useDynamicField;
