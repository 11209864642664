import { Card, List } from 'antd';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import './index.scss';
import { Fragment } from 'react';

const ListView = ({
  columns,
  dataSource,
  pagination = false,
  localePath = '',
  rowKey,
  loading,
  showListGird = true,
  header,
  bordered,
  ...props
}) => {
  const { t } = useTranslation(localePath);
  const renderItem = (record, index) => {
    return (
      <Card bordered>
        <div className={showListGird ? 'list-view-wrapper list-grid-wrapper' : 'list-view-wrapper'}>
          {columns.map((col) => {
            const value = get(record, col.dataIndex);
            return (
              <Fragment key={`${col.dataIndex}-${record[rowKey]}`}>
                {col.showAsTitle ? (
                  <div className="list-title">{col.render(value, record, index)}</div>
                ) : (
                  <div>
                    {`${t(col.title)}: `}
                    {col.render(value, record, index)}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </Card>
    );
  };
  return (
    <List
      grid={{ gutter: 16, column: 1 }}
      dataSource={dataSource}
      pagination={pagination.total <= (pagination.pageSize || 10) ? false : pagination}
      renderItem={(item, index) => <List.Item>{renderItem(item, index)}</List.Item>}
      rowKey={rowKey}
      loading={loading}
      header={props.header && <div className="list-title">{props.header}</div>}
      bordered={bordered}
      className="custom-table-list-wrapper"
    />
  );
};

export default ListView;
