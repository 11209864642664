import axios from 'utils/requestHelper';

const login = (user) => axios.post('v1/clients/sign_in', user);

const fetchUser = () => axios.get('v1/clients/my_account/profile');

const fetchMyAccount = (params) => axios.get('v1/clients/my_account', { params });

const fetchUserReport = () => axios.get('v1/clients/reports/client_balance');

const downloadInvoice = ({ id, params }) =>
  axios.get(`v1/clients/my_account/${id}.pdf`, { params, responseType: 'blob' });

const downloadOrder = ({ id, params }) => axios.get(`v1/clients/invoices/${id}.pdf`, { params, responseType: 'blob' });

const sendAuthCode = () => axios.get('v1/clients/two_factor_auth');
const verify = (params) => axios.put('v1/clients/two_factor_auth', null, { params });
const resendCode = () => axios.get('v1/clients/two_factor_auth/resend_code');

const UserAPI = {
  login,
  fetchUser,
  fetchMyAccount,
  downloadInvoice,
  downloadOrder,
  sendAuthCode,
  verify,
  resendCode,
  fetchUserReport,
};

export default UserAPI;
