import { createSelector } from '@reduxjs/toolkit';
import adapter from './adapter';

const getLoadingState = createSelector(
  (state) => state?.user,
  (user) => user.loading,
);

const getInvoiceLoadingState = createSelector(
  (state) => state?.user,
  (user) => user.invoiceLoader,
);

const getReportLoadingState = createSelector(
  (state) => state?.user,
  (user) => user.reportLoader,
);
const getRecordsState = createSelector(
  (state) => state?.user,
  (user) => user.records,
);

const getErrors = createSelector(
  (state) => state?.user,
  (user) => user.errors,
);

const getCurrentUser = createSelector(
  (state) => state?.user,
  (user) => user.currentUser,
);

const getUserReport = createSelector(
  (state) => state?.user,
  (user) => user.report,
);

const getRecordsTotal = createSelector(
  (state) => state?.user,
  (user) => user.records_total,
);

const selectors = {
  getErrors,
  getCurrentUser,
  getLoadingState,
  getUserReport,
  getInvoiceLoadingState,
  getRecordsTotal,
  getReportLoadingState,
  getRecordsState,
  ...adapter.getSelectors((state) => state.user),
};

export default selectors;
